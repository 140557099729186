import React from "react";
import PageStructure from "../PagesStructure/PageStructure";
import Friends from "./Friends/Friends";
import Top from "./Top/Top";
import UserManager from "./UserManager/UserManager";
import { useSelector } from "react-redux";

const Profile = () => {
  const activeCategory = useSelector((state) => state.profile.activeCategory);

  const categories = [
    { name: "About", value: "" },
    { name: "Friends", value: "friends" },
    { name: "Top", value: "top" },
  ];

  return (
    <PageStructure categories={categories} category={activeCategory}>
      {activeCategory === "" && <UserManager />}
      {activeCategory === "friends" && <Friends />}
      {activeCategory === "top" && <Top />}
    </PageStructure>
  );
};

export default Profile;
