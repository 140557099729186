import React from "react";
import VideoPopup from "../../Common/VideoPopup/VideoPopup";
import VideoPreview from "../../Common/VideoPreview/VideoPreview";
import {
  HeartOutlined,
  InfoCircleOutlined,
  CloseOutlined,
  HeartFilled,
} from "@ant-design/icons";
import s from "./UserManager.module.css";
import { useState, useEffect } from "react";
import axios from "axios";

const UserManager = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videos, setVideos] = useState({
    favorite: null,
    recent: [],
  });

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          "https://blogerverse.com/api/user-video/5"
        );
        const data = await response.json();

        setVideos({
          favorite: data.favorite_videos[0] || null,
          recent: data.non_favorite_videos || [],
        });
      } catch (error) {
        console.error("Ошибка при загрузке видео:", error);
      }
    };

    fetchVideos();
  }, []);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setIsPopupOpen(true);
  };

  const handleSetFavorite = async (videoId) => {
    try {
      await axios.patch(`https://blogerverse.com/api/user-video/5`, {
        video_id: videoId,
      });

      // Обновляем состояние после успешного запроса
      const updatedRecent = videos.recent.map((v) => ({
        ...v,
        is_favorite: v.id === videoId,
      }));

      setVideos({
        favorite:
          videos.recent.find((v) => v.id === videoId) || videos.favorite,
        recent: updatedRecent.filter((v) => v.id !== videoId),
      });
    } catch (error) {
      console.error("Ошибка при обновлении избранного видео:", error);
    }
  };

  const getPopupButtons = (video) => [
    {
      icon: <CloseOutlined />,
      onClick: () => setIsPopupOpen(false),
      className: s.closeButton,
    },
    {
      icon: video.is_favorite ? <HeartFilled /> : <HeartOutlined />,
      onClick: () => !video.is_favorite && handleSetFavorite(video.id),
      className: `${s.favoriteButton} ${
        video.is_favorite ? s.favoriteActive : ""
      }`,
      disabled: video.is_favorite,
    },
    {
      icon: <InfoCircleOutlined />,
      text: "Info",
      onClick: () => {
        /* логика */
      },
      className: s.infoButton,
    },
  ];

  return (
    <div className={s.container}>
      {/* Favorite Video */}
      <div className={s.favoriteSection}>
        <h3 className={s.sectionTitle}>Favorite Video</h3>
        {videos.favorite && (
          <div className={s.favoriteVideo}>
            <VideoPreview
              videoUrl={videos.favorite.video_file}
              previewUrl={null}
              onClick={() => handleVideoClick(videos.favorite)}
            />
          </div>
        )}
      </div>

      {/* Recent Videos */}
      <div className={s.recentSection}>
        <h3 className={s.sectionTitle}>Recent Videos</h3>
        <div className={s.recentVideos}>
          {videos.recent.map((video) => (
            <div key={video.id} className={s.recentVideo}>
              <VideoPreview
                videoUrl={video.video_file}
                previewUrl={null}
                onClick={() => handleVideoClick(video)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Video Popup */}
      <VideoPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        videoUrl={selectedVideo?.video_file}
        buttons={selectedVideo ? getPopupButtons(selectedVideo) : []}
      />
    </div>
  );
};

export default UserManager;
