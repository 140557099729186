import React, { useEffect, useState } from "react";
import { Collapse, Spin } from "antd";
import axios from "axios";
import OneQuest from "./OneQuest/OneQuest";
import s from "./Quests.module.css";
import "./QuestAnt.css";
import { getCategoryTitle } from "../../config/categoryTitles";
const { Panel } = Collapse;

const QuestComponent = () => {
  const [quests, setQuests] = useState({});
  const [loading, setLoading] = useState(false);
  const [openedSet, setOpenedSet] = useState(null);

  const fetchQuests = async (questSet = null) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://blogerverse.com/api/all-quests-user/5/`,
        questSet ? { params: { quest_set: questSet } } : {}
      );
      setQuests((prevState) => ({
        ...prevState,
        ...response.data,
      }));
    } catch (error) {
      console.error("Failed to fetch quests:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuests(); // Initial fetch for all quests
  }, []);

  const handleCollapseChange = (key) => {
    if (!quests[key]) {
      fetchQuests(key); // Fetch quests for the specific quest_set
    }
    setOpenedSet(key);
  };

  if (loading && !Object.keys(quests).length) {
    return <Spin />;
  }

  return (
    <div className={s.questsBlock}>
      {Object.entries(quests).map(([key, questList]) =>
        key !== "Uncategorized" ? (
          <Collapse
            size="large"
            style={{ background: "none", marginTop: "30px", color: "pink" }}
            onChange={() => handleCollapseChange(key)}
            key={key}
          >
            <Panel
              style={{ background: "none", color: "pink" }}
              header={getCategoryTitle(key)}
            >
              {loading && openedSet === key ? (
                <Spin />
              ) : (
                <div className={s.questListCollapse}>
                  {questList.map((quest) => (
                    <OneQuest {...quest} />
                  ))}
                </div>
              )}
            </Panel>
          </Collapse>
        ) : (
          <div className={s.questList}>
            {questList.map((quest) => (
              <OneQuest {...quest} />
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default QuestComponent;
