import React, { useState, useEffect } from "react";
import axios from "axios";
import s from "./Shop.module.css";
import coin from "../../assets/Header/coin.png";
import heart from "../../assets/Header/heart.png";
import { useSelector } from "react-redux";
import ConfirmationModal from "../Common/ConfirmationModal";

const Shop = () => {
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState("");
  const [value, setValue] = useState("shoes"); // Замените 'default' на нужное значение
  // const user = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    axios
      .get(
        `https://blogerverse.com/api/user-items/${user.id}/?filter=locked&type=${value}`
      )
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [value, user.id]);

  const buyItem = (id) => {
    axios
      .post(`https://blogerverse.com/api/user-items/${user.id}/`, {
        item_id: id,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className={s.container}>
      <div className={s.category}>
        <div className={s.categoryMargin}>
          <div
            className={value === "shoes" ? s.categoryBtnActive : s.categoryBtn}
            onClick={() => setValue("shoes")}
          >
            Shoes
          </div>
          <div
            className={value === "eyes" ? s.categoryBtnActive : s.categoryBtn}
            onClick={() => setValue("eyes")}
          >
            Eyes
          </div>
          <div
            className={value === "hair" ? s.categoryBtnActive : s.categoryBtn}
            onClick={() => setValue("hair")}
          >
            Hair
          </div>
          <div
            className={value === "mouth" ? s.categoryBtnActive : s.categoryBtn}
            onClick={() => setValue("mouth")}
          >
            Mouth
          </div>
          <div
            className={value === "body" ? s.categoryBtnActive : s.categoryBtn}
            onClick={() => setValue("body")}
          >
            Body
          </div>
          <div
            className={value === "legs" ? s.categoryBtnActive : s.categoryBtn}
            onClick={() => setValue("legs")}
          >
            Legs
          </div>
        </div>
      </div>
      <div className={s.content}>
        {items.map((item, index) => (
          <Item key={index} item={item} buyItem={buyItem} />
          // <div key={index} className={s.item}>
          //   <img src={`https://blogerverse.com${item.imgShop}`} alt={item.name} />
          //   <div>{item.name}</div>
          // </div>
        ))}
      </div>
    </div>
  );
};

const Item = ({ item, buyItem }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleBuyClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    buyItem(item.id);
    setShowConfirmation(false);
  };

  return (
    <>
      <div className={s.item} onClick={handleBuyClick}>
        <img src={`https://blogerverse.com${item.imgShop}`} alt={item.name} />
        <div className={s.itemInfo}>
          <div className={s.name}>{item.name}</div>
          <div className={s.price}>
            {item.price_coin ? (
              <div className={s.price}>
                <img src={coin} /> {item.price_coin}
              </div>
            ) : (
              <div className={s.price}>
                <img src={heart} /> {item.price_like}
              </div>
            )}
          </div>
          <div className={s.price}>
            {item.likes_bonus ? (
              <div className={s.bonus}>
                {" "}
                X{item.likes_bonus}
                <img src={heart} />
                video multiply
              </div>
            ) : (
              <div className={s.bonus}>
                {" "}
                +{item.coins_bonus}
                <img src={coin} /> per video
              </div>
            )}
          </div>
        </div>
      </div>

      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleConfirm}
        title="Подтверждение покупки"
        message={`Вы уверены, что хотите купить ${item.name}?`}
        price={item.price_coin || item.price_like}
        priceType={item.price_coin ? "coin" : "heart"}
        bonus={item.coins_bonus || item.likes_bonus}
        bonusType={item.coins_bonus ? "coin" : "heart"}
        bonusDescription={item.coins_bonus ? "per video" : "video multiply"}
      />
    </>
  );
};

export default Shop;
