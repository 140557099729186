import {applyMiddleware, combineReducers, compose, createStore} from "redux";

import { authReducer } from './auth/authReducer';
import {thunk} from "redux-thunk";
import {userReducer} from "./user/userReducer";
import {userStatsReducer} from "./user/userStatsReducer";
import {langReducer} from "./language/langReducer";
import {profileReducer} from "./profile/profileReducer";
import {questsReducer} from "./quests/questsReducer";
const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    stats: userStatsReducer,
    lang: langReducer,
    profile: profileReducer,
    quests: questsReducer,
});


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
window.__store__ = store;


