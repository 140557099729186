const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";

const initialState = {
  activeCategory: "",
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: action.payload,
      };
    default:
      return state;
  }
};

export const setActiveCategory = (category) => ({
  type: SET_ACTIVE_CATEGORY,
  payload: category,
});
