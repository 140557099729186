const SET_ACTIVE_QUEST_CATEGORY = "SET_ACTIVE_QUEST_CATEGORY";

const initialState = {
  activeCategory: "",
};

export const questsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_QUEST_CATEGORY:
      return {
        ...state,
        activeCategory: action.payload,
      };
    default:
      return state;
  }
};

export const setActiveQuestCategory = (category) => ({
  type: SET_ACTIVE_QUEST_CATEGORY,
  payload: category,
});
