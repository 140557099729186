import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./TopMenu.module.css";
import coin from "../../assets/Header/coin.png";
import heart from "../../assets/Header/heart.png";
import income_coin_sec from "../../assets/Header/income_coin_sec.png";
import income_like_sec from "../../assets/Header/income_like_sec.png";
import { setStatsUserData } from "../../Redux/user/userStatsReducer";

const TopMenu = () => {
  const user = useSelector((state) => state.user);
  const stats = useSelector((state) => state.stats);
  const [animations, setAnimations] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      incrementScore(stats);
    }, 1000);
    return () => clearInterval(interval); // очищаем интервал при размонтировании
  }, [stats]);

  const incrementScore = (stats) => {
    // Обновляем счетчики и диспатчим изменения в Redux
    const updatedStats = {
      ...stats,
      like_count: stats.like_count + stats.income_like_second,
      coin_count: stats.coin_count + stats.income_coin_second,
    };
    dispatch(setStatsUserData(updatedStats));

    // Анимация для дохода за секунду
    const newAnimation = (
      <span key={Date.now()} className={s.scoreAnimation}>
        +{stats.income_like_second}
      </span>
    );
    setAnimations((prevAnimations) => [...prevAnimations, newAnimation]);

    // Удаляем анимацию после 1 секунды
    setTimeout(() => {
      setAnimations((prevAnimations) => prevAnimations.slice(1));
    }, 1000);
  };

  // Функция форматирования чисел
  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    }
    return num.toString();
  };

  return (
    <div className={s.upPageBlock}>
      <div className={s.statsContainer}>
        <div className={s.statsRow}>
          <div className={s.statsItem}>
            <div className={s.imgBlock}>
              <img src={heart} alt="hearts" />
              <span>{formatNumber(stats.like_count)}</span>
            </div>
            <div className={s.income}>
              <img src={income_like_sec} alt="income hearts" />
              <span>+{stats.income_like_second}/s</span>
            </div>
          </div>
          <div className={s.statsItem}>
            <div className={s.imgBlock}>
              <img src={coin} alt="coins" />
              <span>{formatNumber(stats.coin_count)}</span>
            </div>
            <div className={s.income}>
              <img src={income_coin_sec} alt="income coins" />
              <span>+{stats.income_coin_second}/s</span>
            </div>
          </div>
        </div>
        <div className={s.user}>User: {user.username}</div>
      </div>
    </div>
  );
};

export default TopMenu;
