import React, { useEffect, useState } from "react";
import axios from "axios";
import s from "./Top.module.css"; // Предположим, что у вас есть стили для выделения
import { Avatar, List, Skeleton } from "antd";
import coin from "../../../assets/Header/coin.png";
import heart from "../../../assets/Header/heart.png";
import VideoPopup from "../../Common/VideoPopup/VideoPopup";
import { CloseOutlined } from "@ant-design/icons";

const Description = ({ text, category }) => {
  return (
    <div className={s.description}>
      {text}{" "}
      {category === "like_count" ? <img src={heart} /> : <img src={coin} />}
    </div>
  );
};
const Title = ({ title }) => {
  return <div className={s.title}>{title}</div>;
};

const getRankColor = (rank) => {
  if (rank === 1) return "#FFD700";
  if (rank === 2) return "#C0C0C0";
  if (rank === 3) return "#CD7F32";
  return "#660d72";
};

const LeaderItem = ({ leader, userId, category, onUserClick }) => {
  return (
    <div
      className={s.leaderItem}
      style={{
        borderLeft: `4px solid ${getRankColor(leader.rank)}`,
        backgroundColor:
          leader.user_id === userId ? "rgba(115, 17, 145, 0.3)" : "transparent",
        cursor: "pointer",
      }}
      onClick={() => onUserClick(leader.user_id)}
    >
      <List.Item.Meta
        avatar={
          <div
            className={`${s.rank} ${
              s[`rank${leader.rank <= 3 ? leader.rank : ""}`]
            }`}
            style={{
              backgroundColor:
                leader.rank > 3 ? getRankColor(leader.rank) : "transparent",
            }}
          >
            {leader.rank}
          </div>
        }
        title={<Title title={`User ID: ${leader.user_id}`} />}
        description={
          <Description
            text={`${
              category === "like_count" ? leader.like_count : leader.coin_count
            }`}
            category={category}
          />
        }
      />
    </div>
  );
};

const Top = ({ userId = 5 }) => {
  const [category, setCategory] = useState("like_count");
  const [leaders, setLeaders] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const categories = [
    { name: "Likes", value: "like_count" },
    { name: "Coins", value: "coin_count" },
  ];

  useEffect(() => {
    const fetchLeaders = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://blogerverse.com/api/leaders/${userId}/?sort_by=${category}`
        );
        setLeaders(response.data.top_10);
        setCurrentUser(response.data.current_user);
      } catch (error) {
        console.error("Ошибка при загрузке данных о лидерах:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaders();
  }, [category, userId]);

  const handleUserClick = async (clickedUserId) => {
    try {
      const response = await axios.get(
        `https://blogerverse.com/api/user-video/${clickedUserId}`
      );

      const favoriteVideo = response.data.favorite_videos[0];
      if (favoriteVideo) {
        setSelectedVideo(favoriteVideo);
        setIsPopupOpen(true);
      }
    } catch (error) {
      console.error("Ошибка при загрузке видео пользователя:", error);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedVideo(null);
  };

  const popupButtons = [
    {
      icon: <CloseOutlined />,
      onClick: handleClosePopup,
      className: s.closeButton,
    },
  ];

  return (
    <div className={s.top}>
      <div className={s.categories}>
        {categories.map((cat) => (
          <div
            key={cat.value}
            className={
              category === cat.value ? s.categoryBtnActive : s.categoryBtn
            }
            onClick={() => setCategory(cat.value)}
          >
            {cat.name}
          </div>
        ))}
      </div>

      {loading ? (
        <Skeleton active />
      ) : (
        <div className={s.leaderboard}>
          <h2>Leaderboard ({category === "like_count" ? "Likes" : "Coins"})</h2>

          {leaders.map((leader) => (
            <LeaderItem
              key={leader.user_id}
              leader={leader}
              userId={userId}
              category={category}
              onUserClick={handleUserClick}
            />
          ))}

          {currentUser && currentUser.rank > 10 && (
            <div className={s.currentUser}>
              <h3>Your Rank</h3>
              <LeaderItem
                leader={currentUser}
                userId={userId}
                category={category}
                onUserClick={handleUserClick}
              />
            </div>
          )}
        </div>
      )}

      <VideoPopup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        videoUrl={selectedVideo?.video_file}
        buttons={popupButtons}
      />
    </div>
  );
};

export default Top;
