import s from "./Main.module.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setWardrobeUserData,
  setWardrobeItems,
} from "../../Redux/user/userReducer";
import shop from "../../assets/Buttons/shop.png";
import video from "../../assets/Buttons/video.png";
import friends from "../../assets/Buttons/friends1.png";
import star from "../../assets/Buttons/star.png";
import Header from "../Header";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Joyride from "react-joyride";
import { cloudStorage } from "@telegram-apps/sdk-react";
import { useTutorial } from "../Tutorial/TutorialSteps";
import { setActiveCategory } from "../../Redux/profile/profileReducer";

export const MainPage = ({ activeWard }) => {
  const user = useSelector((state) => state.user);
  useEffect(() => {}, [user]);
  return (
    <div className={!activeWard ? s.main : s.mainWiderobe}>
      <div>
        {!activeWard && <Header />}
        {/* {!activeProfile && !activeUpdates && !activeWard && <MainBtn />} */}
        <Person activeWard={activeWard} />
      </div>
    </div>
  );
};

const Person = ({ activeWard }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [canSendVideo, setCanSendVideo] = useState(true);
  const [run, setRun] = useState(false);
  const { getSteps } = useTutorial("ru");
  const language = "ru";
  const navigate = useNavigate();

  // Проверка оставшегося времени
  const checkRemainingTime = async () => {
    try {
      const response = await axios.get(
        `https://blogerverse.com/api/video/${user.id}/`
      );
      const remainingTime = Math.round(response.data.remaining_time);

      setTimeLeft(remainingTime);
      setCanSendVideo(remainingTime === 0);
    } catch (error) {
      console.error("Ошибка при проверке времени:", error);
    }
  };

  // Запускаем таймер
  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          const newTime = prev - 1;
          if (newTime <= 0) {
            setCanSendVideo(true);
            return 0;
          }
          return newTime;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timeLeft]);

  // Проверяем время при монтировании
  useEffect(() => {
    checkRemainingTime();
  }, [user.id]);

  // Обработчик нажатия на кнопку видео
  const handleVideoClick = async () => {
    if (!canSendVideo || isLoading) return;

    setIsLoading(true);
    try {
      await axios.post(`https://blogerverse.com/api/video/${user.id}/`);
      setRun(true);
      // После успешной отправки проверяем новое время
      await checkRemainingTime();
    } catch (error) {
      console.error("Ошибка при отправке видео:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Форматирование времени
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  const storageKey = "joyrideCompleted"; // Ключ для хранения состояния

  // Функция проверки состояния завершения обучения
  const checkJoyrideStatus = async () => {
    try {
      let completed = false;

      if (window.Telegram?.WebApp?.initData) {
        // Если в Telegram, используем cloudStorage
        completed = await cloudStorage.getCloudStorageItem(storageKey);
      } else {
        // Если в браузере, используем localStorage
        completed = localStorage.getItem(storageKey);
      }

      if (!completed) {
        setRun(true); // Запуск Joyride
      }
    } catch (error) {
      console.error("Ошибка проверки состояния обучения:", error);
    }
  };

  // Функция сохранения состояния завершения обучения
  const saveJoyrideStatus = async () => {
    try {
      if (window.Telegram?.WebApp?.initData) {
        // Сохраняем в Telegram cloudStorage
        await cloudStorage.setCloudStorageItem(storageKey, "true");
      } else {
        // Сохраняем в localStorage
        localStorage.setItem(storageKey, "true");
      }
    } catch (error) {
      console.error("Ошибка сохранения состояния обучения:", error);
    }
  };

  useEffect(() => {
    checkJoyrideStatus();
  }, []);

  const handleJoyrideCallback = async (data) => {
    const { status } = data;

    if (status === "finished" || status === "skipped") {
      await saveJoyrideStatus();
      setRun(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const id = user.id || 5;
        const { data } = await axios.get(
          `https://blogerverse.com/api/usercustomlist?id=${id}`
        );

        dispatch(setWardrobeUserData(data.customisation));
        dispatch(setWardrobeItems(data.items));
      } catch (error) {
        console.error("Ошибка при загрузке данных пользователя:", error);
      }
    };

    fetchUserData();
  }, [user.id, dispatch]);

  return (
    <div>
      <Joyride
        continuous
        run={run}
        callback={handleJoyrideCallback}
        steps={getSteps()}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        locale={{
          back: language === "ru" ? "Назад" : "Back",
          close: language === "ru" ? "Закрыть" : "Close",
          last: language === "ru" ? "Завершить" : "Finish",
          next: language === "ru" ? "Далее" : "Next",
          skip: language === "ru" ? "Пропустить" : "Skip",
        }}
        showProgress
        showSkipButton
      />
      {!activeWard && (
        <div className={s.leftIcons}>
          <img
            src={video}
            alt="video"
            onClick={handleVideoClick}
            style={{
              cursor: canSendVideo ? "pointer" : "not-allowed",
              opacity: canSendVideo ? 1 : 0.6,
            }}
          />
          {timeLeft > 0 && (
            <div className={s.timer}>{formatTime(timeLeft)}</div>
          )}
          <img src={star} alt="star" />
        </div>
      )}
      <div className={s.person}>
        <img
          style={{
            zIndex: "1",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.dummyUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.eyesUrl}`}
        />
        <img
          style={{
            zIndex: "3",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.hairUrl}`}
        />
        <img
          style={{
            zIndex: "3",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.bodyUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.mouthUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.85 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.legsUrl}`}
        />
        <img
          style={{
            zIndex: "1",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.shoesUrl}`}
        />
      </div>
      {!activeWard && (
        <div className={s.rightIcons}>
          <NavLink to="/shop">
            <img
              className={`${s.navIcon} tutorial-shop`}
              src={shop}
              alt="img"
            />
          </NavLink>
          <NavLink
            to="/profile"
            onClick={() => dispatch(setActiveCategory("friends"))}
          >
            <img
              className={`${s.navIcon} tutorial-friends`}
              src={friends}
              alt="img"
            />
          </NavLink>
        </div>
      )}
    </div>
  );
};

// const MainBtn = () => {
//   function registrateUser() {
//     axios.post("https://blogerverse.com/api/create-user/");
//   }
//   return (
//     <div style={{ margin: "0px auto", display: "flex" }}>
//       <button className={s.mainBtn} size="medium" style={{ margin: "0 auto" }}>
//         Создать видео
//       </button>
//     </div>
//   );
// };

export default MainPage;
