import React from "react";
import DailyBonus from "./DailyQ.jsx";
import QuestComponent from "./QuestsList.jsx";
import PageStructure from "../PagesStructure/PageStructure.jsx";
import { useSelector } from "react-redux";

const Quests = () => {
  const activeCategory = useSelector((state) => state.quests.activeCategory);

  const categories = [
    { name: "Quests", value: "" },
    { name: "Daily", value: "daily" },
    { name: "Special", value: "coin" },
  ];

  return (
    <PageStructure
      categories={categories}
      category={activeCategory}
      type="quests"
    >
      {activeCategory === "" && <QuestComponent />}
      {activeCategory === "daily" && <DailyBonus />}
      {activeCategory === "coin" && <QuestComponent type="coin" />}
    </PageStructure>
  );
};

export default Quests;
