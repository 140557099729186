import React from "react";
import TopMenu from "../TopMenu/TopMenu";
import s from "./PageStructure.module.css";
import { useDispatch } from "react-redux";
import { setActiveCategory } from "../../Redux/profile/profileReducer";
import { setActiveQuestCategory } from "../../Redux/quests/questsReducer";

const PageStructure = ({
  children,
  categories,
  category,
  type = "profile",
}) => {
  return (
    <div className={s.page}>
      <TopMenu />
      <div className={s.pageContent}>
        <CategoriesPage
          categories={categories}
          category={category}
          type={type}
        />
        {children}
      </div>
    </div>
  );
};

const CategoriesPage = ({ categories, category, type }) => {
  const dispatch = useDispatch();

  const handleCategoryClick = (value) => {
    if (type === "profile") {
      dispatch(setActiveCategory(value));
    } else if (type === "quests") {
      dispatch(setActiveQuestCategory(value));
    }
  };

  return (
    <div className={s.category}>
      <div className={s.categoryMargin}>
        {categories.map((cat) => (
          <div
            key={cat.value}
            className={
              category === cat.value ? s.categoryBtnActive : s.categoryBtn
            }
            onClick={() => handleCategoryClick(cat.value)}
          >
            {cat.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageStructure;
