import { useNavigate, useLocation } from "react-router-dom";

const translations = {
  ru: {
    shop: {
      title: "Магазин",
      content: "Здесь вы можете купить новые предметы для вашего персонажа",
    },
    friends: {
      title: "Друзья",
      content: "Находите новых друзей и общайтесь с ними",
    },
    wardrobe: {
      title: "Гардероб",
      content: "Примеряйте и комбинируйте предметы одежды",
    },
    updates: {
      title: "Улучшения",
      content:
        "Здесь вы можете улучшить своего персонажа и получить новые возможности",
    },
  },
  en: {
    shop: {
      title: "Shop",
      content: "Here you can buy new items for your character",
    },
    friends: {
      title: "Friends",
      content: "Find new friends and chat with them",
    },
    wardrobe: {
      title: "Wardrobe",
      content: "Try on and combine clothing items",
    },
    updates: {
      title: "Updates",
      content: "Here you can upgrade your character and get new abilities",
    },
  },
};

export const useTutorial = (language = "ru") => {
  const getSteps = () => [
    {
      target: ".tutorial-shop",
      content: translations[language].shop.content,
      title: translations[language].shop.title,
      disableBeacon: true,
      placement: "left",
    },
    {
      target: ".tutorial-friends",
      content: translations[language].friends.content,
      title: translations[language].friends.title,
      placement: "left",
    },
    {
      target: ".tutorial-updates",
      content: translations[language].updates.content,
      title: translations[language].updates.title,
      placement: "bottom",
      spotlightPadding: 5,
    },
  ];

  return { getSteps };
};
