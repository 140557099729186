export const categoryTitles = {
  ingame: "In-Game",
  post_tg: "Telegram",
  telegram_ad: "Ads",
  // Можно добавлять новые заголовки
};

// Вспомогательная функция для получения заголовка
export const getCategoryTitle = (key) => {
  return categoryTitles[key] || key; // Возвращаем оригинальный ключ, если заголовок не найден
};
