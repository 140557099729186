import React from "react";
import s from "./ConfirmationModal.module.css";
import coin from "../../assets/Header/coin.png";
import heart from "../../assets/Header/heart.png";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  price,
  priceType, // 'coin' или 'heart'
  bonus,
  bonusType, // 'coin' или 'heart'
  bonusDescription, // например 'per video' или 'video multiply'
}) => {
  if (!isOpen) return null;

  return (
    <div className={s.modalOverlay} onClick={onClose}>
      <div className={s.modalContent} onClick={(e) => e.stopPropagation()}>
        <h2 className={s.title}>{title}</h2>
        <p className={s.message}>{message}</p>

        {price && (
          <div className={s.priceContainer}>
            <span className={s.priceLabel}>Цена:</span>
            <div className={s.price}>
              {price}
              <img src={priceType === "coin" ? coin : heart} alt={priceType} />
            </div>
          </div>
        )}

        {bonus && (
          <div className={s.bonusContainer}>
            <span className={s.bonusLabel}>Бонус:</span>
            <div className={s.bonus}>
              {bonusType === "coin" ? "+" : "X"}
              {bonus}
              <img src={bonusType === "coin" ? coin : heart} alt={bonusType} />
              <span className={s.bonusDescription}>{bonusDescription}</span>
            </div>
          </div>
        )}

        <div className={s.buttons}>
          <button className={s.cancelButton} onClick={onClose}>
            Отмена
          </button>
          <button className={s.confirmButton} onClick={onConfirm}>
            Подтвердить
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
