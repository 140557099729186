import React, { useState, useEffect } from "react";
import axios from "axios";
import s from "./Friends.module.css";
import { useSelector } from "react-redux";
import friendsimg from "../../../assets/Buttons/friends.png";
import VideoPopup from "../../Common/VideoPopup/VideoPopup";
import { CloseOutlined } from "@ant-design/icons";

const Friend = ({ friend, days, onFriendClick }) => (
  <div className={s.friend} onClick={() => onFriendClick(friend.id)}>
    <img src={friendsimg} alt="avatar" className={s.friendAvatar} />
    <div className={s.friendInfo}>
      <div>ID: {friend.to_user.user_id}</div>
      <div className={s.friendDuration}>Дружба длится: {days}</div>
    </div>
  </div>
);

const Friends = () => {
  const [friends, setFriends] = useState([]);
  const [requests, setRequests] = useState([]);
  const [copySuccess, setCopySuccess] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchFriends = async () => {
      try {
        const response = await axios.get(
          `https://blogerverse.com/api/friendship?telegram_id=${user.id}`
        );
        const { friends, friend_requests } = response.data;
        setFriends(friends);
        setRequests(friend_requests);
      } catch (error) {
        console.error("Ошибка при загрузке данных о друзьях:", error);
      }
    };

    fetchFriends();
  }, [user.id]);

  const handleAccept = async (requestId) => {
    try {
      await axios.post(
        `https://blogerverse.com/api/friendship/respond-to-request/`,
        {
          request_id: requestId,
          action: "accept",
        }
      );
      // Обновляем список после принятия
      const response = await axios.get(
        `https://blogerverse.com/api/friendship?telegram_id=${user.id}`
      );
      setFriends(response.data.friends);
      setRequests(response.data.friend_requests);
    } catch (error) {
      console.error("Ошибка при принятии запроса:", error);
    }
  };

  const handleReject = async (requestId) => {
    try {
      await axios.post(
        `https://blogerverse.com/api/friendship/respond-to-request/`,
        {
          request_id: requestId,
          action: "reject",
        }
      );
      setRequests(requests.filter((request) => request.id !== requestId));
    } catch (error) {
      console.error("Ошибка при отклонении запроса:", error);
    }
  };

  const calculateFriendshipDuration = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const duration = Math.floor((now - createdDate) / (1000 * 60 * 60 * 24));
    return `${duration} дней`;
  };

  const handleCopyFriendLink = async () => {
    const friendLink = `https://blogerverse.com/friend/${user.id}`;
    try {
      await navigator.clipboard.writeText(friendLink);
      setCopySuccess("Ссылка скопирована!");
      setTimeout(() => setCopySuccess(""), 2000);
    } catch (error) {
      console.error("Ошибка при копировании:", error);
      setCopySuccess("Ошибка при копировании");
    }
  };

  const handleShareReferral = () => {
    window.location.href = `https://t.me/share/url?url=${encodeURIComponent(
      user.referal_link
    )}`;
  };

  const handleFriendClick = async (friendId) => {
    try {
      const response = await axios.get(
        `https://blogerverse.com/api/user-video/${friendId}`
      );

      const favoriteVideo = response.data.favorite_videos[0];
      if (favoriteVideo) {
        setSelectedVideo(favoriteVideo);
        setIsPopupOpen(true);
      }
    } catch (error) {
      console.error("Ошибка при загрузке видео друга:", error);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedVideo(null);
  };

  const popupButtons = [
    {
      icon: <CloseOutlined />,
      onClick: handleClosePopup,
      className: s.closeButton,
    },
  ];

  return (
    <div className={s.friendsPage}>
      <h1 className={s.title}>Friends</h1>

      <div className={s.section}>
        <h2 className={s.sectionTitle}>Список друзей</h2>
        <div className={s.friends}>
          {friends.map((friend) => (
            <Friend
              key={friend.id}
              friend={friend}
              days={calculateFriendshipDuration(friend.created_at)}
              onFriendClick={handleFriendClick}
            />
          ))}
        </div>
      </div>

      <div className={s.section}>
        <h2 className={s.sectionTitle}>Запросы на дружбу</h2>
        {requests.map((request) => (
          <div key={request.id} className={s.request}>
            <div style={{display: "flex", alignItems: "center", color: "white", fontSize: "19px", fontWeight: "600" }}  ><img src={friendsimg} alt="avatar" className={s.friendAvatar} />ID: {request.from_user.user_id}</div>
            <div className={s.requestButtons}>
              <button
                className={s.acceptButton}
                onClick={() => handleAccept(request.id)}
              >
                ✔
              </button>
              <button
                className={s.rejectButton}
                onClick={() => handleReject(request.id)}
              >
                ✖
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className={s.actionButtons}>
        <button className={s.actionButton} onClick={handleCopyFriendLink}>
          Ссылка на дружбу
        </button>
        <button className={s.actionButton} onClick={handleShareReferral}>
          Ссылка на инвайт
        </button>
      </div>
      {copySuccess && <div className={s.copySuccess}>{copySuccess}</div>}

      <VideoPopup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        videoUrl={selectedVideo?.video_file}
        buttons={popupButtons}
      />
    </div>
  );
};

export default Friends;
